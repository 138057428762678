






















import type { PropType } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';

import type { AvailableComponentTypes } from './DepartmentBlockTypes';

import CallToAction from '~/components/molecules/CallToAction/CallToAction.vue';
import CategoryLinks from '~/components/molecules/CategoryLinks/CategoryLinks.vue';

export default defineComponent({
  name: 'DepartmentBlock',
  components: {
    CallToAction,
    CategoryLinks
  },
  props: {
    /** TITLE for DepartmentBlock. */
    title: {
      type: String,
      default: null
    },
    /** ARRAY components. */
    children: {
      type: Array as PropType<Array<AvailableComponentTypes>>,
      required: true
    }
  },
  setup({ children }) {
    // infer grid style via children
    const gridStyle = computed(() => {
      /* If the first row consists of children with a width of 4 columns and
       * 2 columns or conversely 2 columns and 4 columns, the design requires
       * us to emove any gutter borders between the two items.
       */
      if (children.length > 1) {
        // the default width is 2 columns
        const firstWidth = children[0].desktopWidth || '2-columns';
        const secondWidth = children[1].desktopWidth || '2-columns';

        if (
          (firstWidth === '4-columns' && secondWidth === '2-columns') ||
          (firstWidth === '2-columns' && secondWidth === '4-columns')
        ) {
          return 'adjoin-first-row';
        }
      }
      return null;
    });

    return {
      gridStyle
    };
  }
});
