









import {
  defineComponent,
  computed,
  ref,
  onMounted,
  onUnmounted
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import debounce from 'lodash.debounce';
import { DisplayImageInterface } from '~/components/atoms/DisplayImage/DisplayImageTypes';
import { getTailwindBreakpoint } from '~/helpers/tailwindcss/getTailwindBreakpoint';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import { EXPONDO_LOGO_DARK_PATH } from '~/constants/expondoLogoPath';

export default defineComponent({
  name: 'DisplayImage',
  components: { ImgView },
  props: {
    /** Url to follow when the image is clicked */
    images: {
      type: Array as PropType<DisplayImageInterface[]>,
      required: true
    }
  },
  setup(props) {
    const sizedImages = computed(() =>
      props.images
        .filter((image) => image.src !== '') // Remove empty images
        .map((image) => {
          // Map to proper url and size based off breakpoint
          return {
            size: getTailwindBreakpoint(image.breakpoint),
            src: image.src,
            alt: image.alt
          };
        })
        .sort((a, b) => {
          // Sort ascending according to breakpoint
          return a.size > b.size ? 1 : -1;
        })
    );

    const getCurrentSource = () => {
      const desiredSource = sizedImages.value.find((source) => {
        return source.size > window.innerWidth;
      });
      return desiredSource || sizedImages.value[sizedImages.value.length - 1];
    };

    const currentSource = ref(sizedImages.value[0]);

    const resizeListener = () => {
      debounce(() => {
        currentSource.value = getCurrentSource();
      }, 300)();
    };

    onMounted(() => {
      currentSource.value = getCurrentSource();
      window.addEventListener('resize', resizeListener);
    });
    onUnmounted(() => window.removeEventListener('resize', resizeListener));

    return {
      currentSource,
      EXPONDO_LOGO_DARK_PATH
    };
  }
});
