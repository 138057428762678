










































































import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';

import Link from '~/components/atoms/Link/Link.vue';

import { ButtonInterface, ButtonDefaults } from '~/components/atoms/Buttons/Button/ButtonTypes';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

import DisplayImage from '~/components/atoms/DisplayImage/DisplayImage.vue';

import { CalloutPosition, ColorOptions } from '~/components/molecules/CallToAction/CallToActionTypes';
import { LinkType } from '~/types/components/Link/LinkType';

export default defineComponent({
  name: 'CallToAction',
  components: {
    DisplayImage,
    Link,
    Button
  },
  props: {
    /** The specifications for the TITLE */
    title: {
      type: String,
      default: ''
    },
    /** The specifications for the DESCRIPTION */
    description: {
      type: String,
      default: ''
    },
    /** The specifications for the IMAGE */
    imageUrl: {
      type: String,
      default: ''
    },
    mobileImageUrl: {
      type: String,
      default: ''
    },
    altImageText: {
      type: String,
      default: ''
    },
    /**  for button type, text, and location */
    button: {
      type: Object as PropType<ButtonInterface>,
      default: () => ButtonDefaults
    },
    /**  for the callout containing the button and text */
    callout: {
      type: String as PropType<CalloutPosition>,
      default: 'left'
    },
    calloutBackground: {
      type: Boolean,
      default: false
    },
    color: {
      type: String as PropType<ColorOptions>,
      default: 'light'
    },
    desktopWidth: {
      type: String,
      default: '2-columns'
    },
    mobileHeight: {
      type: String,
      default: 'fit'
    }
  },
  setup() { return { LinkType }; }
});
