var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"call-to-action__container",class:{
    'callout-left': _vm.callout === 'left',
    'callout-right': _vm.callout === 'right',
    'callout-top': _vm.callout === 'top',
    'callout-bottom': _vm.callout === 'bottom',
    'desktop-width-2-columns': _vm.desktopWidth === '2-columns',
    'desktop-width-4-columns': _vm.desktopWidth === '4-columns',
    'desktop-width-6-columns': _vm.desktopWidth === '6-columns',
    'mobile-height-small': _vm.mobileHeight === 'small',
    'mobile-height-medium': _vm.mobileHeight === 'medium',
    'mobile-height-large': _vm.mobileHeight === 'large'
  }},[_c('div',{staticClass:"call-to-action__callout",class:{
      'call-to-action__callout-background': _vm.calloutBackground
    }},[(_vm.title != '')?_c('h3',{staticClass:"call-to-action__title text-word-wrap",class:{
        'callout-light': _vm.color === 'light',
        'callout-dark': _vm.color === 'dark'
      },domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.description != '')?_c('p',{staticClass:"call-to-action__description",class:{
        'callout-light': _vm.color === 'light',
        'callout-dark': _vm.color === 'dark'
      },domProps:{"textContent":_vm._s(_vm.description)}}):_vm._e(),_vm._v(" "),(_vm.button.text != '' && _vm.button.type != null)?_c('Button',_vm._b({staticClass:"call-to-action__button"},'Button',_vm.button,false)):_vm._e(),_vm._v(" "),(_vm.button.text != '' && _vm.button.type == null)?_c('Link',{staticClass:"call-to-action__link",class:{
        'callout-light': _vm.color === 'light',
        'callout-dark': _vm.color === 'dark'
      },attrs:{"url":_vm.button.url,"text":_vm.button.text,"type":_vm.LinkType.InheritColor}}):_vm._e()],1),_vm._v(" "),(_vm.imageUrl != '' || _vm.mobileImageUrl != '')?_c('DisplayImage',{staticClass:"call-to-action__image",attrs:{"images":[
      { src: _vm.imageUrl, alt: _vm.altImageText },
      { src: _vm.mobileImageUrl, breakpoint: 'md', alt: _vm.altImageText }
    ]}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }