


























































import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { PropType } from 'vue';
import {
  ButtonDefaults,
  ButtonInterface
} from '~/components/atoms/Buttons/Button/ButtonTypes';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import Link from '~/components/atoms/Link/Link.vue';
import {
  LinkInterface
} from '~/components/atoms/Link/LinkTypes';
import { LinkType } from '~/types/components/Link/LinkType';

export default defineComponent({
  name: 'CategoryLinks',
  components: { Button, Link },
  props: {
    title: {
      type: String,
      default: ''
    },
    button: {
      type: Object as PropType<ButtonInterface>,
      default: () => ButtonDefaults
    },
    alternateButton: {
      type: Object as PropType<ButtonInterface>,
      default: () => ButtonDefaults
    },
    subcategories: {
      type: Array as PropType<LinkInterface[]>,
      default: () => []
    },
    desktopWidth: {
      type: String,
      default: '2-columns'
    }
  },
  setup() {
    const dropdownOpen = ref(false);

    const toggleDropdown = (open?: boolean) => {
      if (open !== undefined) {
        dropdownOpen.value = open;
      } else {
        dropdownOpen.value = !dropdownOpen.value;
      }
    };

    return {
      LinkType,
      dropdownOpen,
      toggleDropdown
    };
  }
});
