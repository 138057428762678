import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '~/tailwind.config.js';

const fullTailwindConfig = resolveConfig(tailwindConfig);

export const TailwindBreakpointsArray = [
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl'
] as const;
export type TailwindBreakpoints = typeof TailwindBreakpointsArray[number];

export const getTailwindBreakpoint = (breakpoint: TailwindBreakpoints) => {
  return parseInt(fullTailwindConfig.theme.screens[breakpoint], 10) || Infinity;
};
