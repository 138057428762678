







import type { PropType } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import DepartmentBlock from '~/components/organisms/DepartmentBlock/DepartmentBlock.vue';
import cmsImageToUrl from '@/helpers/cms/cmsImageToUrl';
import { CmsImageResponse } from '~/types/cms/CmsImageResponse';
import { CallToActionInterface } from '~/components/molecules/CallToAction/CallToActionTypes';
import { CategoryLinksInterface } from '~/components/molecules/CategoryLinks/CategoryLinksTypes';

type CallToActionCmsInterface = CallToActionInterface & {
  image: CmsImageResponse,
  mobileImage: CmsImageResponse,
}
type AvailableComponentTypes =
  | CallToActionCmsInterface
  | CategoryLinksInterface;

export default defineComponent({
  name: 'DepartmentBlockCms',
  components: {
    DepartmentBlock
  },
  props: {
    /** TITLE for DepartmentBlock. */
    title: {
      type: String,
      default: ''
    },
    /** ARRAY components. */
    children: {
      type: Array as PropType<Array<AvailableComponentTypes>>,
      required: true
    }
  },
  setup({ children }) {
    const childrenWithUrls = computed(() =>
      children.map((child: AvailableComponentTypes) => {
        if ('image' in child) {
          child.imageUrl = cmsImageToUrl(child.image);
        }
        if ('mobileImage' in child) {
          child.mobileImageUrl = cmsImageToUrl(child.mobileImage);
        }
        return child;
      })
    );

    return { childrenWithUrls };
  }
});
